<template>
  <span></span>
</template>

<script setup>
  import { computed, watch } from 'vue'
  import { usePage } from '@inertiajs/vue3'
  import { toast } from '@/Mixins/toast'

  const page = usePage()
  const message = computed(() => page.props.message)
  const errors = computed(() => page.props.errors)

  watch(message, (newMessage) => {
    if (newMessage) {
      toast(newMessage)
    }
  })

  watch(errors, (newErrors) => {
    if (newErrors) {
      Object.values(newErrors).forEach((error) => {
        toast(error, { type: 'error', hideProgressBar: false })
      })
    }
  }, { deep: true })

  defineOptions({
    name: 'Session'
  })
</script>
