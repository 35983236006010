import { toast as toaster } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export function toast(message, options = {}) {
  if (! typeof message === 'string' || message.length === 0) {
    return
  }
  let toastOptions = {
    hideProgressBar: true,
    theme: 'auto',
    dangerouslyHTMLString: true,
  }
  let allOptions = Object.assign(toastOptions, options)
  toaster(message, allOptions)
}
